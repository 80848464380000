import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
const moment = require('moment');

Vue.use(VueRouter)

const routes = [{
    path: '/migrasi',
    name: 'Migrasi',
    component: () => import( /* webpackChunkName: "Migrasi" */ '../views/migrasi/Migrate.vue'),
    meta: {
      title: 'Migrasi dari V2',
      auth: false
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import( /* webpackChunkName: "Auth" */ '../views/auth/Login.vue'),
    meta: {
      title: 'Login',
      auth: false,
    }
  },
  {
    path: '/login-cs',
    name: 'LoginCustomerService',
    component: () => import( /* webpackChunkName: "Auth" */ '../views/auth/LoginCustomerService.vue'),
    meta: {
      title: 'Login',
      auth: false
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import( /* webpackChunkName: "Auth" */ '../views/auth/Register.vue'),
    meta: {
      title: 'Register',
      auth: false
    }
  },
  {
    path: '/verify',
    name: 'Verify',
    component: () => import( /* webpackChunkName: "Auth" */ '../views/auth/Verify.vue'),
    meta: {
      title: 'Verify',
      auth: false
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import( /* webpackChunkName: "Auth" */ '../views/auth/ResetPassword.vue'),
    meta: {
      title: 'Reset Password',
      auth: false
    }
  },
  {
    path: '/dashboard',
    alias: ['/'],
    name: 'Dashboard',
    component: () => import( /* webpackChunkName: "Home" */ '../views/home/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import( /* webpackChunkName: "Data" */ '../views/profile/Profile.vue'),
    meta: {
      title: 'Profile',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency']
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import( /* webpackChunkName: "Data" */ '../views/data/Contact.vue'),
    meta: {
      title: 'Contact',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/contact/edit',
    name: 'EditContact',
    component: () => import( /* webpackChunkName: "Data" */ '../views/data/EditContact.vue'),
    meta: {
      title: 'Contact',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/group',
    name: 'Group',
    component: () => import( /* webpackChunkName: "Data" */ '../views/data/Group.vue'),
    meta: {
      title: 'Contact Group',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/group/edit',
    name: 'EditGroup',
    component: () => import( /* webpackChunkName: "Data" */ '../views/data/EditGroup.vue'),
    meta: {
      title: 'Contact Group',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/send',
    name: 'Send',
    component: () => import( /* webpackChunkName: "Outgoing" */ '../views/outgoing/Send.vue'),
    meta: {
      title: 'Send Message',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/outgoing',
    name: 'Outgoing',
    component: () => import( /* webpackChunkName: "Outgoing" */ '../views/outgoing/Outgoing.vue'),
    meta: {
      title: 'Outgoing',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/outgoing/detail',
    name: 'OutgoingDetail',
    component: () => import( /* webpackChunkName: "Outgoing" */ '../views/outgoing/Detail.vue'),
    meta: {
      title: 'Outgoing',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/broadcast',
    name: 'Broadcast',
    component: () => import( /* webpackChunkName: "Outgoing" */ '../views/broadcast/Broadcast.vue'),
    meta: {
      title: 'Broadcast',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/incoming',
    name: 'Incoming',
    component: () => import( /* webpackChunkName: "Incoming" */ '../views/incoming/Incoming.vue'),
    meta: {
      title: 'Incoming',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/incoming/detail',
    name: 'IncomingDetail',
    component: () => import( /* webpackChunkName: "Incoming" */ '../views/incoming/Detail.vue'),
    meta: {
      title: 'Incoming',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/autoreply',
    name: 'Autoreply',
    component: () => import( /* webpackChunkName: "Autoreply" */ '../views/autoreply/Autoreply.vue'),
    meta: {
      title: 'Autoreply',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/autoreply/add',
    name: 'AddAutoreply',
    component: () => import( /* webpackChunkName: "Autoreply" */ '../views/autoreply/Add.vue'),
    meta: {
      title: 'Autoreply',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/autoreply/detail',
    name: 'DetailAutoreply',
    component: () => import( /* webpackChunkName: "Autoreply" */ '../views/autoreply/Detail.vue'),
    meta: {
      title: 'Autoreply',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/google-form-generator',
    name: 'GoogleFormGenerator',
    component: () => import( /* webpackChunkName: "GoogleScriptGenerator" */ '../views/google/GoogleForm.vue'),
    meta: {
      title: 'Google Form',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/google-spreadsheet-generator',
    name: 'GoogleSpreadsheetGenerator',
    component: () => import( /* webpackChunkName: "GoogleScriptGenerator" */ '../views/google/GoogleSpreadsheet.vue'),
    meta: {
      title: 'Google Spreadsheet',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/web-whatsapp',
    name: 'WebWhatsapp',
    component: () => import( /* webpackChunkName: "CustomerService" */ '../views/chat/SelectDevice.vue'),
    meta: {
      title: 'Web Whatsapp',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/web-whatsapp/:id',
    name: 'WebWhatsapp',
    component: () => import( /* webpackChunkName: "CustomerService" */ '../views/chat/Chat.vue'),
    meta: {
      title: 'Web Whatsapp',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/customer-service',
    name: 'CustomerService',
    component: () => import( /* webpackChunkName: "CustomerService" */ '../views/cs/CustomerService.vue'),
    meta: {
      title: 'Customer Service',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/customer-service/detail',
    name: 'CustomerServiceDetail',
    component: () => import( /* webpackChunkName: "CustomerService" */ '../views/cs/Detail.vue'),
    meta: {
      title: 'Customer Service',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/chat-widget',
    name: 'ChatWidget',
    component: () => import( /* webpackChunkName: "ChatWidget" */ '../views/chat_widget/ChatWidget.vue'),
    meta: {
      title: 'Chat Widget',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/chat-widget/detail',
    name: 'ChatWidgetDetail',
    component: () => import( /* webpackChunkName: "ChatWidget" */ '../views/chat_widget/Detail.vue'),
    meta: {
      title: 'Chat Widget',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/campaign',
    name: 'Campaign',
    component: () => import( /* webpackChunkName: "Campaign" */ '../views/campaign/Campaign.vue'),
    meta: {
      title: 'Campaign',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/campaign/detail',
    name: 'CampaignDetail',
    component: () => import( /* webpackChunkName: "Campaign" */ '../views/campaign/Detail.vue'),
    meta: {
      title: 'Campaign',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  /*{
    path: '/order-online',
    name: 'OrderOnline',
    component: () => import( /* webpackChunkName: "OrderOnline" *//* '../views/orderonline/OrderOnline.vue'),
    meta: {
      title: 'Order Online',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },*/
  {
    path: '/order-online/detail',
    name: 'OrderOnlineDetail',
    component: () => import( /* webpackChunkName: "OrderOnline" */ '../views/orderonline/Detail.vue'),
    meta: {
      title: 'Order Online',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/link-rotator',
    name: 'LinkRotator',
    component: () => import( /* webpackChunkName: "LinkRotator" */ '../views/linkrotator/LinkRotator.vue'),
    meta: {
      title: 'Link Rotator',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/link-rotator/create',
    name: 'LinkRotatorCreate',
    component: () => import( /* webpackChunkName: "LinkRotator" */ '../views/linkrotator/Create.vue'),
    meta: {
      title: 'Link Rotator',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/link-rotator/detail',
    name: 'LinkRotatorDetail',
    component: () => import( /* webpackChunkName: "LinkRotator" */ '../views/linkrotator/Detail.vue'),
    meta: {
      title: 'Link Rotator',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/documentation',
    name: 'Documentation',
    component: () => import( /* webpackChunkName: "Integration" */ '../views/integration/Documentation.vue'),
    meta: {
      title: 'Documentation',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/webhook',
    name: 'Webhook',
    component: () => import( /* webpackChunkName: "Integration" */ '../views/integration/Webhook.vue'),
    meta: {
      title: 'Webhook',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/chat-gpt',
    name: 'ChatGPT',
    component: () => import( /* webpackChunkName: "Integration" */ '../views/integration/ChatGPT.vue'),
    meta: {
      title: 'ChatGPT',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/chat-gpt/detail',
    name: 'ChatGPT',
    component: () => import( /* webpackChunkName: "Integration" */ '../views/integration/ChatGPTDetail.vue'),
    meta: {
      title: 'ChatGPT Detail',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/device',
    name: 'Device',
    component: () => import( /* webpackChunkName: "Device" */ '../views/device/Device.vue'),
    meta: {
      title: 'Device',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/device/edit',
    name: 'EditDevice',
    component: () => import( /* webpackChunkName: "Device" */ '../views/device/EditDevice.vue'),
    meta: {
      title: 'Device',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/device/scan',
    name: 'ScanDevice',
    component: () => import( /* webpackChunkName: "Device" */ '../views/device/Scan.vue'),
    meta: {
      title: 'Device',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/billing',
    name: 'Billing',
    component: () => import( /* webpackChunkName: "Billing" */ '../views/billing/Billing.vue'),
    meta: {
      title: 'Billing',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency']
    }
  },
  {
    path: '/billing/buy/:id',
    name: 'BillingBuy',
    component: () => import( /* webpackChunkName: "Billing" */ '../views/billing/Buy.vue'),
    meta: {
      title: 'Billing',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency']
    }
  },
  {
    path: '/billing/transaction',
    name: 'BillingTransaction',
    component: () => import( /* webpackChunkName: "Billing" */ '../views/billing/Transaction.vue'),
    meta: {
      title: 'Billing',
      auth: true,
      role: ['admin', 'user', 'agency', 'user-agency']
    }
  },
  {
    path: '/agency/users',
    name: 'UsersAgency',
    component: () => import( /* webpackChunkName: "Agency" */ '../views/agency/Users.vue'),
    meta: {
      title: 'Agency Page',
      auth: true,
      role: ['agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/agency/users/edit',
    name: 'EditUserAgency',
    component: () => import( /* webpackChunkName: "Agency" */ '../views/agency/EditUser.vue'),
    meta: {
      title: 'Agency Page',
      auth: true,
      role: ['agency'],
      expiredGuard: true,
    }
  },
  {
    path: '/admin/laporan',
    name: 'Laporan',
    component: () => import( /* webpackChunkName: "Admin" */ '../views/admin/Laporan.vue'),
    meta: {
      title: 'Admin Page',
      auth: true,
      role: ['admin'],
      expiredGuard: true,
    }
  },
  {
    path: '/admin/statistics',
    name: 'Statistics',
    component: () => import( /* webpackChunkName: "Admin" */ '../views/admin/Statistics.vue'),
    meta: {
      title: 'Admin Page',
      auth: true,
      role: ['admin'],
      expiredGuard: true,
    }
  },
  {
    path: '/admin/device-diskonek',
    name: 'DeviceDiskonek',
    component: () => import( /* webpackChunkName: "Admin" */ '../views/admin/DeviceDiskonek.vue'),
    meta: {
      title: 'Admin Page',
      auth: true,
      role: ['admin'],
      expiredGuard: true,
    }
  },
  {
    path: '/admin/pengaturan',
    name: 'Pengaturan',
    component: () => import( /* webpackChunkName: "Admin" */ '../views/admin/Pengaturan.vue'),
    meta: {
      title: 'Admin Page',
      auth: true,
      role: ['admin'],
      expiredGuard: true,
    }
  },
  {
    path: '/admin/paket',
    name: 'Paket',
    component: () => import( /* webpackChunkName: "Admin" */ '../views/admin/Paket.vue'),
    meta: {
      title: 'Admin Page',
      auth: true,
      role: ['admin'],
      expiredGuard: true,
    }
  },
  {
    path: '/admin/paket/edit',
    name: 'EditPaket',
    component: () => import( /* webpackChunkName: "Admin" */ '../views/admin/EditPaket.vue'),
    meta: {
      title: 'Admin Page',
      auth: true,
      role: ['admin'],
      expiredGuard: true,
    }
  },
  {
    path: '/admin/users',
    name: 'ListUser',
    component: () => import( /* webpackChunkName: "Admin" */ '../views/admin/Users.vue'),
    meta: {
      title: 'Admin Page',
      auth: true,
      role: ['admin'],
      expiredGuard: true,
    }
  },
  {
    path: '/admin/users/edit',
    name: 'EditUser',
    component: () => import( /* webpackChunkName: "Admin" */ '../views/admin/EditUser.vue'),
    meta: {
      title: 'Admin Page',
      auth: true,
      role: ['admin'],
      expiredGuard: true,
    }
  },
  {
    path: '/admin/message-template',
    name: 'MessageTemplate',
    component: () => import( /* webpackChunkName: "Admin" */ '../views/admin/MessageTemplate.vue'),
    meta: {
      title: 'Admin Page',
      auth: true,
      role: ['admin'],
      expiredGuard: true,
    }
  },
  {
    path: '/admin/message-template/edit',
    name: 'EditMessageTemplate',
    component: () => import( /* webpackChunkName: "Admin" */ '../views/admin/EditMessageTemplate.vue'),
    meta: {
      title: 'Admin Page',
      auth: true,
      role: ['admin'],
      expiredGuard: true,
    }
  },
  {
    path: '/admin/messages',
    name: 'MessagesUser',
    component: () => import( /* webpackChunkName: "Admin" */ '../views/admin/MessagesUser.vue'),
    meta: {
      title: 'Admin Page',
      auth: true,
      role: ['admin'],
      expiredGuard: true,
    }
  },
  {
    path: '/admin/withdraw',
    name: 'Withdraw',
    component: () => import( /* webpackChunkName: "Admin" */ '../views/admin/Withdraw.vue'),
    meta: {
      title: 'Admin Page',
      auth: true,
      role: ['admin'],
      expiredGuard: true,
    }
  },
  {
    path: '/admin/coupon',
    name: 'Coupon',
    component: () => import( /* webpackChunkName: "Admin" */ '../views/admin/Coupon.vue'),
    meta: {
      title: 'Admin Page',
      auth: true,
      role: ['admin'],
      expiredGuard: true,
    }
  },
  {
    path: '/admin/coupon/edit',
    name: 'EditCoupon',
    component: () => import( /* webpackChunkName: "Admin" */ '../views/admin/EditCoupon.vue'),
    meta: {
      title: 'Admin Page',
      auth: true,
      role: ['admin'],
      expiredGuard: true,
    }
  },

//CUSTOMER SERVICE
{
  path: '/cs-page/dashboard',
  name: 'DashboardCustomerService',
  component: () => import( /* webpackChunkName: "CustomerService" */ '../views/customer-service/Dashboard.vue'),
  meta: {
    title: 'Dashboard',
    auth: true,
    role: ['customer_service']
  }
},
{
  path: '/cs-page/web-whatsapp',
  name: 'WebWhatsappCustomerServiceSelectDevice',
  component: () => import( /* webpackChunkName: "CustomerService" */ '../views/customer-service/WebWhatsappSelectDevice.vue'),
  meta: {
    title: 'Web Whatsapp',
    auth: true,
    role: ['customer_service']
  }
},
{
  path: '/cs-page/web-whatsapp/:id',
  name: 'WebWhatsappCustomerService',
  component: () => import( /* webpackChunkName: "CustomerService" */ '../views/customer-service/Chat.vue'),
  meta: {
    title: 'Web Whatsapp',
    auth: true,
    role: ['customer_service']
  }
},
{
  path: '/cs-page/order',
  name: 'OrderCustomerService',
  component: () => import( /* webpackChunkName: "CustomerService" */ '../views/customer-service/order/Index.vue'),
  meta: {
    title: 'Order',
    auth: true,
    role: ['customer_service']
  }
},
{
  path: '/cs-page/order/follow-up',
  name: 'OrderCustomerService',
  component: () => import( /* webpackChunkName: "CustomerService" */ '../views/customer-service/order/FollowUp.vue'),
  meta: {
    title: 'Order',
    auth: true,
    role: ['customer_service']
  }
},
{
  path: '/cs-page/kategori',
  name: 'KategoriCustomerService',
  component: () => import( /* webpackChunkName: "CustomerService" */ '../views/customer-service/category/Index.vue'),
  meta: {
    title: 'Kategori',
    auth: true,
    role: ['customer_service']
  }
},
{
  path: '/cs-page/kategori/detail',
  name: 'KategoriCustomerService',
  component: () => import( /* webpackChunkName: "CustomerService" */ '../views/customer-service/category/Detail.vue'),
  meta: {
    title: 'Kategori',
    auth: true,
    role: ['customer_service']
  }
},
{
  path: '/cs-page/autotext',
  name: 'AutotextCustomerService',
  component: () => import( /* webpackChunkName: "CustomerService" */ '../views/customer-service/autotext/Index.vue'),
  meta: {
    title: 'Autotext',
    auth: true,
    role: ['customer_service']
  }
},
{
  path: '/cs-page/autotext/detail',
  name: 'AutotextCustomerService',
  component: () => import( /* webpackChunkName: "CustomerService" */ '../views/customer-service/autotext/Detail.vue'),
  meta: {
    title: 'Autotext',
    auth: true,
    role: ['customer_service']
  }
},
{
  path: '/cs-page/toko',
  name: 'TokoCustomerService',
  component: () => import( /* webpackChunkName: "CustomerService" */ '../views/customer-service/toko/Index.vue'),
  meta: {
    title: 'Toko',
    auth: true,
    role: ['customer_service']
  }
},
{
  path: '/cs-page/toko/detail',
  name: 'TokoCustomerService',
  component: () => import( /* webpackChunkName: "CustomerService" */ '../views/customer-service/toko/Detail.vue'),
  meta: {
    title: 'Toko',
    auth: true,
    role: ['customer_service']
  }
},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (localStorage.getItem('token')) {
    const jwtPayload = parseJwt(localStorage.token);
    if (jwtPayload.exp < Date.now() / 1000) {
      console.log("expired")
      store.dispatch("auth_logout")
      localStorage.removeItem('role')
      localStorage.removeItem('token')
      localStorage.removeItem('refresh_token')
      localStorage.removeItem('socket_token')
      router.go("/login")
    }
  }

  if (to.matched.some(record => record.meta.auth) == "all") {
    next()
  } else if (!to.matched.some(record => record.meta.auth) && store.state.auth.isLoggedIn) {
    next("/dashboard")
  } else if (to.matched.some(record => record.meta.auth) && !store.state.auth.isLoggedIn) {
    router.push("/login")
  } else {
    next()
  }
});

router.beforeEach((to, from, next) => {
  if (store.state.auth.isLoggedIn && localStorage.getItem('role') != "customer_service") {
    let now = moment.utc().valueOf();
    if (store.state.auth.package.expired_at < now) {
      if (to.matched.some(record => record.meta.expiredGuard)) {
        router.push("/billing")
      } else {
        next()
      }
    }
  }
  next()
})


//CHECK ROLE
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth) && store.state.auth.isLoggedIn) {
    if (localStorage.getItem('role')) {
      let myRole = localStorage.getItem('role')
      let role = to.meta.role

      //FIND ROLE
      for (let i = 0; i < role.length; i++) {
        if (role[i] == myRole) {
          next()
          return
        }
      }

      console.log("invalid")
      store.dispatch("auth_logout")
      localStorage.removeItem('role')
      localStorage.removeItem('token')
      localStorage.removeItem('refresh_token')
      localStorage.removeItem('socket_token')
      router.push("/login")
    } else {
      console.log("invalid")
      store.dispatch("auth_logout")
      localStorage.removeItem('role')
      localStorage.removeItem('token')
      localStorage.removeItem('refresh_token')
      localStorage.removeItem('socket_token')
      router.push("/login")
    }
  } else {
    next()
  }
});

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(Buffer.from(base64, "base64").toString("ascii").split("").map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export default router
